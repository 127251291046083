import { breakpoints } from '../../variables.yml';
import { getWindowWidth } from '../helpers.js';

function init() {
	// Register scrolltrigger plugin
	gsap.registerPlugin( ScrollTrigger, ScrollToPlugin );

	// Navigation toggle animation
	const nav = document.querySelector( '.overlay-navigation' );
	const nav_menu = document.querySelector( '.absolute-menu' );
	const close = document.querySelector( '.nav-close' );
	const open = document.querySelector( '.nav-open' );
	const nav_tl = gsap.timeline();
	const body_classes = document.body.classList;
	nav_tl
		.set( nav, { xPercent: 100 } )
		.set( nav_menu, { xPercent: 50 } );

	close.addEventListener( 'click', () => {
		nav_tl
			.to( nav, { xPercent: 100 } )
			.to( nav_menu, {xPercent: 50 } );
			if 	( body_classes.contains( 'navigation-open' ) ) {
				document.body.classList.remove( 'navigation-open' );
			}
	} );
	open.addEventListener( 'click', () => {
		nav_tl
			.to( nav, { opacity: 1, duration: 0.2 } )
			.to( nav, { xPercent: 0, duration: 0.5, ease: "power2.out" }, '<' )
			.to( nav_menu, { xPercent: 0, ease: "power2.out" }, '-=0.3' );
			if ( !body_classes.contains( 'navigation-open' ) ) {
				document.body.classList.add( 'navigation-open' );
			}
	} );

	// Project intro animation
	const project_intro = document.querySelector( '.project__intro' );
	if ( project_intro ) {
		const header = document.querySelector( '.header' );
		const main_image = document.querySelector( '.project__main-image' );
		const document_main = document.querySelector( '.pin-section' );
		const project_tl = gsap.timeline();
		header.classList.add( 'header--below' );

		let translate_height = main_image.offsetTop * -1;

		window.addEventListener( 'resize', () => {
			translate_height = main_image.offsetTop * -1;
		} );
		project_tl
			.set( document.body, { position: 'fixed', width: '100%' } )
			.set( document_main, { zIndex: 999 } )
			.set( main_image, { y: translate_height, height: '100vh' } )
			.to( document.body, { autoAlpha: 1} )
			.to( main_image, { y: 0, duration: 0.5, ease: 'power2.in', height: 'auto' }, '+=1.5' )
			.to( document.body, { position: 'initial' } )
			.to( document_main, { zIndex: 'initial' } );
	}

	// Fixed timeline with animation
	const timeline = document.querySelector( '.timeline__bar' );
	if ( timeline ) {
		const links = gsap.utils.toArray( '.timeline__link' );
		const timeline_items = gsap.utils.toArray( '.timeline__item' );
		const timeline_bar = document.querySelector( '.timeline__years' );

		ScrollTrigger.create( {
			trigger: timeline,
			pin: true,
			pinSpacing: false,
			endTrigger: '.timeline__items',
			end: 'bottom bottom-=200',
		} );

		timeline_items.forEach( ( item, i ) => {
			gsap.to( item, {
				scrollTrigger: {
					trigger: item,
					start: 'top center',
					onEnter: () => setActive( links[i], i ),
					onEnterBack: () => setActive( links[i], i ),
				}
			} );
		} );

		links.forEach( link => {
			link.addEventListener( 'click', ( e ) => {
				e.preventDefault();
				gsap.to( window, {
					duration: 0.5,
					scrollTo: e.target.getAttribute( 'href' )
				} );
			} );
		} );

		function setActive( link, index ) {
			links.forEach( el => {
				el.classList.remove( 'active' );
			} );
			link.classList.add( 'active' );
			const rect = links[index].getBoundingClientRect();
			timeline_bar.scrollLeft = ( rect.width * index ) + rect.width / 2;
		}
	}

	// Vision grid animation
	const vision_grid = document.querySelector( '.vision-grid' );
	if ( vision_grid ) {
		let offset_top = document.querySelector( 'header' ).getBoundingClientRect().height;
		const images = document.querySelectorAll( '.vision-grid__image' );
		images.forEach( image => {
			if ( image.complete ) {
				image.classList.remove( 'vision-grid__image--initial' );
			}
		} );

		const tl = gsap.timeline( {
			scrollTrigger: {
				trigger: '.pin-section',
				pin: true,
				start: `top top+=${offset_top}`,
				endTrigger: '.vision-grid__main',
				end: `top+=1000 top+=${offset_top}`,
				scrub: 0.5,
			}
		} );

		tl
			.from( '.vision-grid__image--main', {
				scale: 2.81,
				ease: 'power1.in',
				duration: 2,
			} )
			.from( '.vison-grid__grid', {
				yPercent: 100,
				ease: 'power1.in',
				duration: 2,
			}, '<' );

		const grid_columns = gsap.utils.toArray( '.vision-grid__column' );
		grid_columns.forEach( ( column, i ) => {
			if ( i % 2 === 1 ) {
				tl.to( column, {
					y: -30,
					duration: 2,
					ease: 'power3.out',
				}, 1 );
			}
		} );

		// Sticky nav
		ScrollTrigger.create( {
			trigger: '.header',
			pin: true,
			endTrigger: '.vision-grid__main',
			end: `top+=1000 top+=${offset_top}`,
			id: 'navigation',
		} );
	}

	// Project fixed key info
	const key_info = document.querySelector( '.key-info' );
	const tab_links = document.querySelectorAll( '.tabs__link' );
	if ( key_info && tab_links ) {

		const container_end = document.querySelector( '.project__left' );
		const scroll_trigger = ScrollTrigger.create( {
			trigger: key_info,
			pin: true,
			start: 'top top',
			endTrigger: container_end,
			end: 'bottom bottom-=100',
		} );
		scroll_trigger.disable();

		if ( getWindowWidth() > breakpoints.f.width ) {
			scroll_trigger.enable();
		}

		window.addEventListener( 'resize', () => {
			if ( getWindowWidth() > breakpoints.f.width ) {
				scroll_trigger.enable();
				return;
			}
			scroll_trigger.disable();
		} );


		if ( tab_links.length > 1 ) {
			const tab_panels = document.querySelectorAll( '.tabs__panel' );
			tab_links.forEach( link => {
				const panel = document.getElementById( link.getAttribute( 'data-panel' ) );
				link.addEventListener( 'click', () => {
					for ( let i = 0; i < tab_links.length; i++ ) {
						tab_links[i].classList.remove( 'tabs__link--active' );
					}
					for ( let j = 0; j < tab_panels.length; j++ ) {
						tab_panels[j].classList.remove( 'tabs__panel--active' );
					}
					link.classList.add( 'tabs__link--active' );
					panel.classList.add( 'tabs__panel--active' );
					scroll_trigger.refresh();
				}, false );
			} );

		}
	}

	// Framework lightbox open animation
	const frameworks = document.querySelectorAll( '.framework-trigger' );
	if ( frameworks.length > 0 ) {
		const triggers = gsap.utils.toArray( frameworks );
		triggers.forEach( trigger => {
			const container = document.querySelector( `[data-id="${trigger.id}"]` );
			const panel = container.querySelector( '.framework__content' );
			const close = container.querySelector( '.framework__close' );
			const tl = gsap.timeline();
			tl.set( panel, {
				yPercent: 100
			} );
			let animation_open = gsap.timeline( { paused: true } );
			let animation_close = gsap.timeline( { paused: true } );

			animation_open
				.from( container, {
					autoAlpha: 0
				} )
				.to( panel, {
					yPercent: 5
				}, '<' );

			animation_close
				.to( panel, {
					yPercent: 100,
					duration: 1
				} )
				.to( container, {
					autoAlpha: 0
				}, '<' );

			trigger.addEventListener( 'click', ( e ) => {
				e.preventDefault();
				document.body.classList.add( 'framework-open' );
				animation_open.play( 0 );
			}, false );

			close.addEventListener( 'click', closeFramework );
			close.addEventListener( 'keydown', ( e ) => {
				if ( e.key === "Enter" ) {
					closeFramework();
				}
			} );
			
			function closeFramework() {
				document.body.classList.remove( 'framework-open' );
				animation_close.play( 0 );
			}
			
		} );
	}

	

	// Homepage intro animation
	const hp_intro = document.querySelector( '.animate-intro' );
	if ( hp_intro ) {
		gsap.from( hp_intro, { yPercent: 40 } );
	}

	// Sector listing animation
	const sector_container = document.querySelector( '.sector' );
	if ( sector_container ) {
		let window_width = window.innerWidth;
		const items = gsap.utils.toArray( '.sector-item' );

		function determineAnimation( elements_object ) {
			let anim_settings = {}
			if ( getWindowWidth() > breakpoints.f.width ) {
				anim_settings = {
					image_right_width: '10%',
					content_left_width: '90%',
					item_width: '90%'
				}
			} else {
				anim_settings = {
					image_right_width: '30%',
					content_left_width: '70%',
					item_width: '100%'
				}
			}
			const tl = gsap.timeline();
			tl.set( elements_object.image_right, {
				width: anim_settings.image_right_width
			} )
				.set( elements_object.content_left, {
					width: anim_settings.content_left_width
				} )
				.set( [elements_object.close, elements_object.text, elements_object.button], {
					display: 'none'
				} )
				.set( elements_object.item, {
					backgroundColor: 'transparent',
					width: anim_settings.item_width
				} );
			tl.to( elements_object.item, {
				autoAlpha: 1,
			} );
			return tl;
		}

		function determineAnimationOpen( elements_object ) {
			let anim_settings = {};
			if ( getWindowWidth() > breakpoints.f.width ) {
				anim_settings = {
					content_left_width: '40%',
					image_right_width: '60%',
					content_padding: '4rem'
				}
			} else {
				anim_settings = {
					content_left_width: '100%',
					image_right_width: '100%',
					content_padding: '2rem'
				}
			}
			let animation_open = gsap.timeline( { paused: true } );
			return animation_open
				.to( elements_object.item, {
					backgroundColor: 'white',
					width: '100%',
				} )
				.to( [elements_object.close, elements_object.text, elements_object.button], {
					display: 'block',
				}, '<' )
				.to( [elements_object.close, elements_object.text, elements_object.button], {
					autoAlpha: 1,
				}, '<' )
				.to( elements_object.content_left, {
					width: anim_settings.content_left_width,
				}, '<' )
				.to( elements_object.image_right, {
					width: anim_settings.image_right_width
				}, '<' )
				.to( elements_object.content_inner, {
					padding: anim_settings.content_padding,
				}, '<' )
				.to( elements_object.border, {
					x: -100,
					opacity: 0
				}, '<' );
		}
		

		items.forEach( item => {
			const image_right = item.querySelector( '.sector-item__right' );
			const content_left = item.querySelector( '.sector-item__left' );
			const content_inner = item.querySelector( '.sector-item__content' );
			const close = item.querySelector( '.sector-item__close' );
			const text = item.querySelector( '.sector-item__text' );
			const button = item.querySelector( '.sector-item__button-container' );
			const border = item.nextElementSibling && item.nextElementSibling.classList.contains( 'sector-item__border' ) ? item.nextElementSibling : null;

			determineAnimation( {
				image_right: image_right,
				content_left: content_left,
				content_inner: content_inner,
				close: close,
				text: text,
				button: button,
				item: item,
			} );

			let animation_open = determineAnimationOpen( {
				item: item,
				close: close,
				text: text,
				button: button,
				content_left: content_left,
				image_right: image_right,
				content_inner: content_inner,
				border: border,
			} );

			window.addEventListener( 'resize', () => {
				if ( window.innerWidth !== window_width ) {
					determineAnimation( {
						image_right: image_right,
						content_left: content_left,
						content_inner: content_inner,
						close: close,
						text: text,
						button: button,
						item: item,
					} );
					animation_open = determineAnimationOpen( {
						item: item,
						close: close,
						text: text,
						button: button,
						content_left: content_left,
						image_right: image_right,
						content_inner: content_inner,
						border: border,
					} );
					window_width = window.innerWidth;
				}
			} );

			item.addEventListener( 'click', ( e ) => {
				openSectorItem();
			}, false );

			item.addEventListener( 'keydown', ( event ) => {
				if ( event.key === 'Enter' ) {
					openSectorItem();
				}
			} );

			close.addEventListener( 'click', ( e ) => {
				e.stopPropagation();
				animation_open.reverse( 0 );
				item.classList.remove( 'open' );
			} );

			function openSectorItem() {
				if ( !item.classList.contains( 'open' ) ) {
					animation_open.play( 0 );
					item.classList.add( 'open' );
				}
			}		

		} );
	}

	// Expertise and Career page animation (floating image animation)
	const floating_images = document.querySelector( '.floating-container' );
	if ( floating_images ) {
		const images = gsap.utils.toArray( '.floating-container__image' );
		const window_width = window.innerWidth + 500;
		let image_tl = gsap.timeline({
			force3D: true,
		});
		image_tl.pause();
		image_tl.addLabel( 'start', 0);
		images.forEach( ( image, index ) => {
			image_tl.to( image, {
				x: window_width,
				ease: 'none',
				duration: getDuration( image, index ),
				repeat: -1,
			}, `start+=${index * 1.5}` );
		} );
		image_tl.seek( 10 );
		image_tl.play();
	}

	function getDuration( image, index ) {
		const offset = index > 2 ? index / 2 : 0;
		const speed = parseInt( image.getAttribute( 'data-speed' ) ) + offset;
		return speed;
	}
}

window.addEventListener( 'load', () => {
	init();
} );
export class Search {
	constructor(
		search_container = document.getElementById( 'js-search' ),
		input = document.getElementById( 'js-search-input' ),
		search_result_container = document.getElementById( 'js-search-results-receptacle' ),
		filters = [ 'sectors', 'services', 'insights', 'projects', 'people', 'contact' ],
		suggested_template_id = 'suggested-template',
		endpoint = 'search/'
	) {
		this.search_container = search_container;
		this.input = input;
		this.buffer_container = document.createElement( 'div' );
		this.search_result_container = search_result_container;
		this.search_filters_container = document.querySelector( '#js-search-filters' ) || null;
		this.search_filters = document.querySelectorAll( '.search-box__filter' ) || null;
		this.filters = filters;
		this.suggested_template_id = suggested_template_id;
		this.endpoint = endpoint;

		const delay = ( () => {
			let timer = 0;
			return function( callback, ms ) {
				clearTimeout( timer );
				timer = setTimeout( callback, ms );
			};
		} )();

		this.input.addEventListener( 'keyup', () => {
			delay( () => {
				if ( this.input.value.length > 0 ) {
					const filter = this.search_filters_container.querySelector( '.search-box__filter--active' ).dataset.searchFilterHandle;
					this.makeRequest( this.endpoint, this.input.value, this.search_result_container, filter );
					this.search_container.classList.add( 'loading' );
				} else if ( this.input.value.length === 0 && this.endpoint === 'people-search/' ) {
					this.makeRequest( 'people', '', this.search_result_container, '' );
				} else {
					this.search_result_container.innerHTML = "";
				}
			}, 100 );
		} );

		if ( this.search_filters_container && this.search_filters ) {
			this.search_filters.forEach( selected_filter => {
				selected_filter.addEventListener( 'click', () => {
					this.searchFilter( selected_filter );
				});
				selected_filter.addEventListener( 'keydown', ( event ) => {
					if ( event.key === 'Enter' ) {
					  this.searchFilter( selected_filter );
					}
				}); 
			} );
		}
	}

	searchFilter( selected_filter ) {
		this.search_filters.forEach( search_filter => {
			search_filter.classList.remove( 'search-box__filter--active' )
		} )
		selected_filter.classList.add( 'search-box__filter--active' );

		this.makeRequest( this.endpoint, this.input.value, this.search_result_container, selected_filter.dataset.searchFilterHandle );
		this.search_container.classList.add( 'loading' );
	}

	renderTemplate( id, variables ) {
		const template = document.getElementById( id ).textContent;
		if ( !template ) {
			return '';
		}
		if ( id === 'suggested-template' && variables.section === 'People' ) {
			const people_template = document.getElementById( 'suggested-people-template' ).textContent;
			return people_template.replace( /%([^% ]+)%/gi, function( match, tag_name ) {
				return variables[tag_name] || '';
			} );
		} else {
			return template.replace( /%([^% ]+)%/gi, function( match, tag_name ) {
				return variables[tag_name] || '';
			} );
		}
	}

	makeRequest( endpoint, value, search_result_container, filter ) {
		const base_url = document.getElementById( 'js-base-url' ).getAttribute( 'data-url' );
		const url = base_url + endpoint + value.replace( / /g, '_' ) + '.json';

		const filters = this.filters;
		const self = this;

		ajax( {
			type: 'get',
			url: url,
			timeout: 10000,
			onSuccess: function( data ) {
				var data = JSON.parse( data );
				var data = data.data;
				var html = '';
				self.search_container.classList.remove( 'loading' );
				search_result_container.innerHTML = '';
				if ( !data || data.length < 1 ) {
					if ( self.suggested_template_id === 'suggested-contact-template' ) {
						search_result_container.innerHTML = '<p class="contact__no-result">No results.</p>';
					} else if ( self.endpoint == 'people-search/' ) {
						search_result_container.innerHTML = '<div class="grid__item"><p class="search-box__result__title">No results.</p></div>';
					} else {
						search_result_container.innerHTML = '<p class="search-box__result__title">No results.</p>';
					}
					return;
				}
				data.forEach( result => {
					if ( !result.hasOwnProperty( 'title' ) ) {
						return;
					}
					if ( filter === 'other' && ( filters.includes( result.section.handle ) ) ) {
						return;
					}
					if ( filter && ( filter!== 'other' ) && ( filter !== result.section.handle ) ) {
						return;
					}
					html += self.renderTemplate( self.suggested_template_id, {
						'id': result.id,
						'title': result.title,
						'url': result.url,
						'section': result.section.name,
						'image': result.image,
						'job_title': result.job_title,
						'email': result.email,
					} );
					self.buffer_container.innerHTML = html;
				} );
				if ( !self.buffer_container.hasChildNodes() ) {
					search_result_container.innerHTML = '<p class="search-box__result__title">No results.</p>';
					return;
				}
				while ( self.buffer_container.hasChildNodes() ) {
					search_result_container.appendChild( self.buffer_container.firstChild );
				}
			},
			onError: function() {
				if ( console ) {
					console.log( 'Ajax error!' );
				}
			}
		} );
	}
}
